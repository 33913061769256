import React, { useState, useEffect } from 'react';
import styles from './DescriptionField.scss';

interface DescriptionFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength: number;
}

const DescriptionField: React.FC<DescriptionFieldProps> = ({
  value = '',
  onChange,
  maxLength = 100,
}: DescriptionFieldProps): JSX.Element => {
  const [charCount, setCharCount] = useState<number>(0);
  const textareaId = 'description-textarea';

  useEffect(() => {
    setCharCount(value.length);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const newValue = e.target.value;
    if (newValue.length <= maxLength) {
      onChange(e);
    }
  };

  return (
    <div className={String(styles.container)}>
      <label
        htmlFor={textareaId}
        className={String(styles.label)}
      >
        Description
      </label>
      <textarea
        id={textareaId}
        value={value}
        onChange={handleChange}
        className={String(styles.textarea)}
        rows={2}
      />
      <div className={String(styles.helperText)}>
        Maximum of
        {' '}
        {maxLength}
        {' '}
        characters
      </div>
      {charCount > (maxLength * 0.9) && (
        <div className={`${String(styles.counter)} ${charCount === maxLength ? String(styles.error) : String(styles.warning)}`}>
          {maxLength - charCount}
          {' '}
          characters remaining
        </div>
      )}
    </div>
  );
};

export default DescriptionField;
