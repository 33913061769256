export enum DIRECTION_TYPE {
  TO_SITE = 'to site',
  FROM_SITE = 'from site',
  TO_PROVIDER = 'to provider',
  TO_OTHER_LOCATION = 'to other location',
}

export const DIRECTIONS = [
  { label: 'Shipping to work order location', value: DIRECTION_TYPE.TO_SITE },
  { label: 'Shipping to provider', value: DIRECTION_TYPE.TO_PROVIDER },
  { label: 'Shipping to other location', value: DIRECTION_TYPE.TO_OTHER_LOCATION },
  { label: 'Ship from site or return to carrier location', value: DIRECTION_TYPE.FROM_SITE },
];

export const DIRECTIONS_FOR_PROVIDERS = [
  { label: 'Shipping to work order location', value: DIRECTION_TYPE.TO_SITE },
  { label: 'Ship from site or return to carrier location', value: DIRECTION_TYPE.FROM_SITE },
];

export const DIRECTIONS_WITHOUT_OTHER_LOCATION = [
  { label: 'Shipping to work order location', value: DIRECTION_TYPE.TO_SITE },
  { label: 'Shipping to provider', value: DIRECTION_TYPE.TO_PROVIDER },
  { label: 'Ship from site or return to carrier location', value: DIRECTION_TYPE.FROM_SITE },
];

export enum CARRIER_TYPE {
  UPS = 'ups',
  FEDEX = 'fedex',
  USPS = 'usps',
  OTHER = 'other',
}

export const CARRIERS = [
  {
    value: CARRIER_TYPE.UPS,
    label: 'UPS',
    url: 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums={trackingNumber}',
    regs: [
      /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/,
    ],
  },
  {
    value: CARRIER_TYPE.FEDEX,
    label: 'Fedex',
    url: 'https://www.fedex.com/apps/fedextrack/?tracknumbers={trackingNumber}',
    regs: [
      /(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/,
      /\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/,
      /^[0-9]{15}$/,
    ],
  },
  {
    value: CARRIER_TYPE.USPS,
    label: 'USPS',
    url: 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1={trackingNumber}',
    regs: [
      /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/,
      /^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/,
      /^91[0-9]+$/,
      /^[A-Za-z]{2}[0-9]+US$/,
    ],
  },
  {
    value: CARRIER_TYPE.OTHER,
    label: 'Other',
  },
];
