import * as React from 'react';
import classNames from 'classnames';
import { v2, InfoIcon, ProgressBar } from '@fieldnation/platform-components';
import { ScoreType, ProgressBarValue, IReliabilityScore } from './types.d';
import css from './Reliability.scss';

const { Modal } = v2;

interface CancellationDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  providerId: number;
  reliabilityScore: IReliabilityScore;
}

export default function CancellationDetails(props: CancellationDetailsProps): JSX.Element {
  const {
    isOpen, onClose, providerId, reliabilityScore,
  } = props;
  const cancellationScore = reliabilityScore?.[ScoreType.Cancellation] ?? {};
  const totalAssignment = cancellationScore?.details?.totalAssignment ?? 0;
  const totalCancellation = cancellationScore?.details?.totalCancellation ?? 0;
  const earlyCancellation = cancellationScore?.details?.earlyCancellation ?? 0;
  const lateCancellation = cancellationScore?.details?.lateCancellation ?? 0;
  const noShow = cancellationScore?.details?.noShow ?? 0;
  return (
    <Modal
      isOpen={isOpen}
      header="Cancellations"
      onClose={onClose}
      size="medium"
    >
      <div className={css['r-modal-container']}>
        <p className={css['r-body-title']}>
          {window?.context?.id && (window?.context?.id === providerId) ? 'Your ' : ''}
          Cancellations
        </p>
        <p
          className={classNames(
            'text-muted u-padding-bottom--sm',
            css['flex-vertical-center'],
          )}
        >
          <span className={css['r-body-sub-text']}>On recent assignments</span>
          <InfoIcon color="default">
            Recent assignments are defined as the
            last 30 assignments occurring within the past 2 years.
          </InfoIcon>
        </p>
        <div className={classNames('u-padding-bottom--lg', css['flex-space-between'])}>
          <div className={css['r-inline-count-wrapper']}>
            <span className={css['r-inline-count-number']}>{ totalAssignment }</span>
            <span className={css['r-inline-count-label']}>Assignments</span>
          </div>
          <div className={css['r-inline-count-wrapper']}>
            <span className={css['r-inline-count-number']}>{ totalCancellation }</span>
            <span className={css['r-inline-count-label']}>Cancellations</span>
          </div>
        </div>
        <div className="u-padding-bottom--sm">
          <div className="u-padding-bottom--sm">
            <div className={classNames('u-padding-bottom--sm', css['flex-space-between'])}>
              <div>Early cancellations</div>
              <strong>
                { earlyCancellation }
              </strong>
            </div>
            <ProgressBar
              currentProgress={earlyCancellation as ProgressBarValue}
              maxProgress={totalAssignment as ProgressBarValue}
              fillColor="yellow"
            />
          </div>
          <div className="u-padding-bottom--sm">
            <div className={classNames('u-padding-bottom--sm', css['flex-space-between'])}>
              <div>Late cancellations</div>
              <strong>{ lateCancellation }</strong>
            </div>
            <ProgressBar
              currentProgress={lateCancellation as ProgressBarValue}
              maxProgress={totalAssignment as ProgressBarValue}
              fillColor="orange"
            />
          </div>
          <div className="u-padding-bottom--sm">
            <div className={classNames('u-padding-bottom--sm', css['flex-space-between'])}>
              <div>No shows</div>
              <strong>{noShow}</strong>
            </div>
            <ProgressBar
              currentProgress={noShow as ProgressBarValue}
              maxProgress={totalAssignment as ProgressBarValue}
              fillColor="red"
            />
          </div>
        </div>
        <div className="u-padding-top--md">
          <p className={classNames(css['r-text-smaller'], css['r-spacing-xs'])}>
            <strong>Early:</strong>
            {' '}
            Canceled before the confirmation window begins.
          </p>
          <p className={classNames(css['r-text-smaller'], css['r-spacing-xs'])}>
            <strong>Late:</strong>
            {' '}
            Canceled after the confirmation window begins.
          </p>
          <p className={classNames(css['r-text-smaller'], css['r-spacing-xs'])}>
            <strong>Confirmation Window:</strong>
            {' '}
            Begins at 6 AM the day before the scheduled time.
          </p>
        </div>
      </div>
    </Modal>
  );
}
