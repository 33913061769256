import React from 'react';
import styles from './FormError.scss';

interface FormErrorProps {
  // eslint-disable-next-line react/require-default-props
  message?: string;
}

const FormError: React.FC<FormErrorProps> = ({ message = 'This value is required' }: FormErrorProps) => {
  if (!message) return null;
  return (
    <div>
      <span className={styles.messageText}>
        {message}
      </span>
    </div>
  );
};

export default FormError;
