import {
  CARRIERS, DIRECTIONS, DIRECTIONS_FOR_PROVIDERS, DIRECTIONS_WITHOUT_OTHER_LOCATION,
} from './constants';
import { DirectionOption } from './types.d';

// eslint-disable-next-line import/prefer-default-export
export const getCarrierFromTrackingNumber = (trackingNumber: string): {
  carrier: string,
  carrierLabel: string,
  trackingLink: string,
} => {
  const result = CARRIERS.reduce((acc, {
    label, value, regs, url,
  }) => (
    regs && regs.find((reg) => trackingNumber?.match(reg))
      ? { carrier: value, carrierLabel: label, trackingLink: (url || '').replace('{trackingNumber}', trackingNumber) }
      : acc
  ), { carrier: 'other', carrierLabel: 'Other', trackingLink: '' });

  return result;
};

export const getDirections = (
  isProvider: boolean,
  disableOtherLocation: boolean,
): Array<DirectionOption> => {
  if (isProvider) return DIRECTIONS_FOR_PROVIDERS;
  if (disableOtherLocation) return DIRECTIONS_WITHOUT_OTHER_LOCATION;
  return DIRECTIONS;
};

export const getCarrierLabel = (carrier: string): string => {
  const label = CARRIERS.find((c) => c.value === carrier)?.label || '';
  if (label === 'other') return '';
  return label;
};
