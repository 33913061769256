import * as React from 'react';
import { useRecoilValue } from 'recoil';
import WrapSection from './WrapSection';
import ReliabilityFallback from '../Components/ReliabilityFallback';
import ProviderReliability from '../../ProviderReliability/ProviderReliability';
import reliabilityQuery from './reliability.query';
import { userIdQuery } from '../queries/user.query';
import css from '../../ProviderReliability/Reliability.scss';

interface Props {
  collapse?: boolean;
}

export default function Reliability(props: Props): JSX.Element {
  const { collapse = false } = props;
  const reliabilityScores = useRecoilValue(reliabilityQuery);
  const providerId = useRecoilValue(userIdQuery);

  return (
    <WrapSection
      label="Reliability"
      collapse={collapse}
      fullWidth
    >
      <React.Suspense fallback={<ReliabilityFallback />}>
        <ProviderReliability
          providerId={providerId || 0}
          reliabilityScore={reliabilityScores}
          wrapperClasses={{ [css['reliability-score-items']]: true }}
        />
      </React.Suspense>
    </WrapSection>
  );
}

Reliability.defaultProps = {
  collapse: false,
};
